<!--商户采购汇总页-->
<template>
  <!-- 路径 -->
  <div class="path">
    <span><router-link to="/"> 首页</router-link></span> 
    &nbsp; &gt; &nbsp;
    <span>市场采购报表</span>
  </div>
  <div class="card-box">
    <div class="flex-start mb_15">
      <div class="news_title1">市场采购汇总统计图</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    <div ref="echart_ref" class="com-chart"></div>
    <div class="flex-start mb_15">
      <div class="news_title1">市场采购汇总表</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    <div class="search-div">  
      商户名称：
      <el-input placeholder="请输入商户名称" prefix-icon="search" v-model="shopName" clearable class="width"></el-input>            
      时间区间：
      <el-date-picker
        v-model="dateValue"
        type="daterange"        
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间" 
        format="YYYY年MM月DD日"
        value-format="YYYY-MM-DD"             
      />
      经营品类：
      <el-input placeholder="请输入经营商品类别" prefix-icon="search" v-model="businessFormatName" clearable class="width"></el-input>
      <el-button type="primary" icon="search" @click="getSummaryData">查询</el-button>
      <el-button type="primary" icon="Download" @click="getExportData">导出</el-button>
    </div>   

    <el-table v-loading="tableLoading" :data="listData" highlight-current-row stripe border
        style="width: 100%" header-row-class-name="header-row" :row-class-name="tableRowClassName">
      <!-- <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" label="序" width="43"></el-table-column>-->
      <el-table-column prop="shopName" label="商户名称" show-overflow-tooltip min-width="200" />
      <el-table-column prop="boothNo" label="摊位号" min-width="100" />
      <el-table-column prop="businessFormatName" label="品类" min-width="100" />
      <el-table-column prop="tradeCount" label="采购笔数" min-width="100" />
      <!-- <el-table-column prop="tradeDate" label="交易时间" min-width="150" /> -->
      <el-table-column prop="tradeAmount" label="采购金额" min-width="100" align="right" />
    </el-table>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth, formatDate} from '@/utils/datetime_utils.js'
import {formatCurrency} from '@/utils/chineseNum.js'
export default {
  data () {
    return {
      tableLoading: false,
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      purchaseTotalAmount: 0, // 采购金额合计
      purchaseTotalCount: 0, // 采购笔数合计      
      listData: [],
      shopName: '', // 商户名称
      businessFormatName: '', // 商户经营类别
      dateValue: [],
      // startDate: '', // 交易开始日期
      // endDate: '', // 交易结束日期
      // chartInstance: null,
      isFirst: true // 是否第一次访问(不是分页访问)   
    }
  },
  mounted () {
    // 当前月第一天和最后一天
    this.dateValue[0] = getFirstDayOfCurrentMonth()
    this.dateValue[1] = getLastDayOfCurrentMonth()
    this.initEchart()
    this.getSummaryData()  
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 限制当前日期以后的日期不可选 :disabled-date="disabledDate"
    disabledDate(time) {
      return time.getTime() > Date.now()
    },
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    tableRowClassName({rowIndex}) {
      // console.log('row, rowIndex=', rowIndex)
      if (rowIndex === 0) {
        return 'Summary-row'
      }
      return ''
    },
    getSummaries() {
      // const { columns, data } = param
      // const sums = []
      // sums[0] = '合计'
      // sums[3] = this.purchaseTotalCount
      // sums[4] = this.purchaseTotalAmount
      // return sums
      const sums = {
        shopName: '合计',
        tradeCount: this.purchaseTotalCount,
        tradeAmount: this.purchaseTotalAmount
      }
      this.listData.unshift(sums)      
    },    
    //`${this.dateValue[1]} 23:59:59`
    // 采购金额/笔数合计
    async getSummaryData () {
      var en = {
        position: 'merchantTrade_queryPurchaseSummary',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue[0],
          endDate: this.dateValue[1]
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('PurchaseSummary', res)
      if (res.state.code === 10000) {
        // this.$message.success(res.state.message)
        this.purchaseTotalAmount = '¥'+ formatCurrency(res.body[0].purchaseTotalAmount, 2)
        this.purchaseTotalCount = formatCurrency(res.body[0].purchaseTotalCount, 0)
        this.isFirst = true
        this.getListData()     
      } else {
        this.$message.error(res.state.message)
      }
    },
    async getListData () {
      this.tableLoading = true
      var en = {
        position: 'merchantTrade_queryPurchaseGroupByMerchantDetail',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          businessFormatName: this.businessFormatName,
          shopName: this.shopName,
          startDate: this.dateValue[0],
          endDate: this.dateValue[1]
        },
        pageable: {
            pageNumber: this.currentPage,
            pageSize: this.pagesize
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en) 
      // console.log('PurchaseDetail', res)
      if (res.state.code === 10000) {
        // this.$message.success(res.state.message) 
        // 获取前15条数据
        // this.allData = res.body.slice(0,15)  
        this.tableTotal = res.body.totalCount
        if (this.isFirst) {
          this.isFirst = false
          this.getEchartData(res.body.list)
        }        
        this.listData = res.body.list
        this.listData.forEach(p => {
          p.tradeAmount = '¥'+ formatCurrency(p.tradeAmount, 2)
        }) 
        this.getSummaries()             
      } else {
        this.$message.error(res.state.message)
      }
      this.tableLoading = false
    },
    async getExportData () {
      var dt = this.dateValue[0] === this.dateValue[1] ? formatDate(this.dateValue[0], 'yyyy-MM-dd') : formatDate(this.dateValue[0], 'yyyy-MM-dd') + '至' + formatDate(this.dateValue[1], 'yyyy-MM-dd')
      var exportFileName = `市场采购汇总表${dt}`
      var en = {
        "sheetDatas":
          {
            "fields": [
              "shopName",
              "boothNo",
              "businessFormatName",
              "tradeCount",
              "tradeAmount"
            ],
            "titles": [
              "商户名称",
              "摊位号",
              "品类",
              "采购笔数",
              "采购金额"
            ],
            "sheetName": '市场采购汇总表'
          },        
        "param": {
            "position": "merchantTrade_queryPurchaseGroupByMerchantDetail",
            "paramMap": {
              "orgId": "9ce5a4d757f9d6434008b1449d20a641",
              "businessFormatName": this.businessFormatName,
              "shopName": this.shopName,
              "startDate": this.dateValue[0],
              "endDate": this.dateValue[1]
            }
        },
        "exportFileName": exportFileName
      }
      const res = await this.$http.postdown(this.$api.queryExport, en)     
      // console.log('Export', res)
      if (res.status === 200) {
        this.$fileDownload(res.data, `${exportFileName}.xlsx`)
      } else {
        this.$message.error(res.statusText)
      }
    },
    // 图表数据
    getEchartData (list) {
      // 获取前10条数据
      var allData = list.slice(0,10) 
      var shopNames = allData.map(c => c.shopName)
      var tradeCounts = allData.map(c => c.tradeCount)
      var tradeAmounts = allData.map(c => c.tradeAmount)
      // console.log('data=', allData, shopNames, tradeCounts, tradeAmounts)
      this.updateEchart(shopNames, tradeCounts, tradeAmounts)
    },
    // 初始化图表
    initEchart () {
      // this.chartInstance = markRaw(this.$echarts.init(this.$refs.chart_ref))
      this.chartInstance = this.$echarts.init(this.$refs.echart_ref)
      const initOption = {
        // title: {
        //   text: '商户采购统计图'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          top: 100,        
          bottom: 80
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          top: 35,
          data: ['采购金额', '采购笔数' ]
        },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { interval: 0, rotate: 20 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '采购金额（元）',
            // min: 0,
            // max: 25,
            // interval: 10
          },
          {
            type: 'value',
            name: '采购笔数',
            // min: 0,
            // max: 25,
            //interval: 20,
            // axisLabel: {
            //   formatter: '{value} ml'
            // }
          }
        ],
        series: [  
          {
            name: '采购金额',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '采购笔数',
            type: 'line' ,
            yAxisIndex: 1
          }
        ]
      }
      this.chartInstance.setOption(initOption)      
    },
    // 更新图表
    updateEchart (shopNames, tradeCounts, tradeAmounts) {
      var chartTitle = this.dateValue[0] === this.dateValue[1] ? formatDate(this.dateValue[0], 'yyyy年MM月dd日') : formatDate(this.dateValue[0], 'yyyy年MM月dd日') + '至' + formatDate(this.dateValue[1], 'yyyy年MM月dd日')
      const dataOption = {
        title: {
          text: chartTitle + '市场采购统计图'
        },
        xAxis: {
          data: shopNames            
        },
        series: [
          {
            data: tradeAmounts
          },
          {
            data: tradeCounts
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      // 调用图表对象的resize才能产生效果
      this.chartInstance.resize()
    }
  }
}
</script>

<style>
.page_bgcolor {
  background-color: #EEF1F6;
}
.big-font {
  margin: 50px 16px;
  font-size: 28px;
  font-weight:bold;
  color: #fff;
  letter-spacing: 3px;
}
.card-box {
  margin-bottom: 50px;
  border-radius: 6px;
  background-color: #fff;
  min-height: 600px;
}
</style>
